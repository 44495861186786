// extracted by mini-css-extract-plugin
export var bannerSuper = "terms-conditions-module--bannerSuper--1r8r0";
export var button = "terms-conditions-module--button--dz-n5";
export var canadianSupply = "terms-conditions-module--canadianSupply--7R-et";
export var caption = "terms-conditions-module--caption--1lbe9";
export var ccs = "terms-conditions-module--ccs--5P0bq";
export var column = "terms-conditions-module--column--EuaIC";
export var display = "terms-conditions-module--display--NTRsY";
export var display1 = "terms-conditions-module--display1--544Fe";
export var docTemplate = "terms-conditions-module--docTemplate--9ATS2";
export var email = "terms-conditions-module--email--w6Mnn";
export var footer = "terms-conditions-module--footer--qypJv";
export var footerContent = "terms-conditions-module--footerContent--U1KZi";
export var footerL = "terms-conditions-module--footerL--WNSkU";
export var footerLinks = "terms-conditions-module--footerLinks--z-KQC";
export var footerR = "terms-conditions-module--footerR--sTTGp";
export var fullNavBar = "terms-conditions-module--fullNavBar--4EsQO";
export var gToUpload = "terms-conditions-module--gToUpload--wIJDh";
export var iconButton = "terms-conditions-module--iconButton--ZhkNJ";
export var iconButton1 = "terms-conditions-module--iconButton1--UDdUr";
export var iconFilled = "terms-conditions-module--iconFilled--8ZX-S";
export var inAdditionTo = "terms-conditions-module--inAdditionTo--FBNvd";
export var infowebsitecom = "terms-conditions-module--infowebsitecom---XwBb";
export var logo = "terms-conditions-module--logo--fN606";
export var logo1 = "terms-conditions-module--logo1--dncb+";
export var navbarContent = "terms-conditions-module--navbarContent--2HijP";
export var navbarContentMobile = "terms-conditions-module--navbarContentMobile--HoPhj";
export var navigation = "terms-conditions-module--navigation--ywhA-";
export var navigation1 = "terms-conditions-module--navigation1--mLvF2";
export var p = "terms-conditions-module--p--XpxRC";
export var p1 = "terms-conditions-module--p1--2SUFs";
export var privacyPolicy = "terms-conditions-module--privacyPolicy--2BhIu";
export var shippingPolicy = "terms-conditions-module--shippingPolicy--xz-DB";
export var subtitleLarge = "terms-conditions-module--subtitleLarge--YRbLK";
export var tcpSupplyLogoIcon = "terms-conditions-module--tcpSupplyLogoIcon--3Vg8m";
export var tcpSupplyLogoIcon2 = "terms-conditions-module--tcpSupplyLogoIcon2--vBlSi";
export var termsConditions = "terms-conditions-module--termsConditions--llGUU";
export var textColumn = "terms-conditions-module--textColumn--ZQ-3J";
export var textbody = "terms-conditions-module--textbody---e1vf";
export var textbody10 = "terms-conditions-module--textbody10---M-8h";
export var title = "terms-conditions-module--title--r9I7J";
export var visaIcon = "terms-conditions-module--visaIcon--uj71w";